import axios from 'axios';
import {
  GET_USERS,
  GET_USER,
  UPDATE_USER,
  NEW_USER,
  CHANGE_PASSWORD,
} from './types';
import { returnErrors } from './messages';
import { tokenConfig, logout } from './auth';
import { useNavigate } from 'react-router-dom';

export const getUsers = () => (dispatch, getState) => {
  axios
    .get('/api/users/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getUser = (userid) => (dispatch, getState) => {
  axios
    .get('/api/users/' + userid + '/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const updateUser = (payload, navigate, setLoading) => (
  dispatch,
  getState
) => {
  axios
    .patch('/api/users/' + payload.id + '/', payload, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
      });
      setLoading(false);
      navigate('/');
    })
    .catch((err) => {
      setLoading(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const changePassword = (payload, setLoading) => (dispatch, getState) => {
  axios
    .patch('/api/auth/password', payload, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CHANGE_PASSWORD,
      });
      setLoading(false);
      console.log('password changed!');
      dispatch(logout());
    })
    .catch((err) => {
      setLoading(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const newUser = (payload, navigate, setLoading) => (
  dispatch,
  getState
) => {
  axios
    .post('/api/users/', payload, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: NEW_USER,
      });
      setLoading(false);
      navigate('/');
    })
    .catch((err) => {
      setLoading(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
