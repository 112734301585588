// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orsuccess {
    color: green;
}

.orinvalid {
    color: red;
}

.ormain {
    margin-top: 56px;
}
`, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".orsuccess {\n    color: green;\n}\n\n.orinvalid {\n    color: red;\n}\n\n.ormain {\n    margin-top: 56px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
