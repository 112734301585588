import { DISPLAY_MESSAGE, HIDE_MESSAGE } from './types';

export const displayError = (msg, color) => (dispatch) => {
  dispatch({
    type: DISPLAY_MESSAGE,
    payload: { messageToDisplay: msg, messageColor: 'danger' },
  });
};

export const displayMessage = (msg, color) => (dispatch) => {
  console.log('in displayMessage');
  dispatch({
    type: DISPLAY_MESSAGE,
    payload: { messageToDisplay: msg, messageColor: color },
  });
};

export const hideMessage = () => (dispatch) => {
  dispatch({
    type: HIDE_MESSAGE,
  });
};
