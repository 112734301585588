import axios from 'axios';
import {
  GET_AGENCIES,
  GET_AGENCY,
  GET_AGENCY_MAP,
  NEW_AGENCY,
  UPDATE_AGENCY,
  GET_ROLES,
  GET_TRACTS,
} from './types';
import { returnErrors } from './messages';
import { tokenConfig } from './auth';
import { useNavigate } from 'react-router-dom';

export const getAgencies = () => (dispatch, getState) => {
  axios
    .get('/api/agencies/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_AGENCIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getRoles = () => (dispatch, getState) => {
  axios
    .get('/api/roles', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ROLES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getTracts = () => (dispatch, getState) => {
  axios
    .get('/api/tracts', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TRACTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getAgency = (agencyid) => (dispatch, getState) => {
  axios
    .get('/api/agencies/' + agencyid + '/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_AGENCY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getAgencyMap = (agencyid) => (dispatch, getState) => {
  const config = tokenConfig(getState);
  config['params'] = { agency: agencyid };
  axios
    .get('/api/agencymap', config)
    .then((res) => {
      dispatch({
        type: GET_AGENCY_MAP,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const updateAgency = (payload, navigate, setLoading) => (
  dispatch,
  getState
) => {
  axios
    .patch('/api/agencies/' + payload.id + '/', payload, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_AGENCY,
      });
      setLoading(false);
      navigate('/');
    })
    .catch((err) => {
      setLoading(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const newAgency = (payload, navigate, setLoading) => (
  dispatch,
  getState
) => {
  axios
    .post('/api/agencies/', payload, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: NEW_AGENCY,
      });
      setLoading(false);
      navigate('/');
    })
    .catch((err) => {
      setLoading(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
