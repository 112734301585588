import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { getRounds } from '../../actions/rounds';

const months = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export function display_month(text) {
  return months[text.substring(5, 7)] + ' ' + text.substring(0, 4);
}

export const RoundRow = (props) => {
  const navigate = useNavigate();
  const round = props.round;
  return (
    <tr
      style={{ cursor: 'pointer' }}
      onClick={(event) => {
        navigate('/round/' + round.roundnum);
      }}
    >
      <td>{round.roundnum}</td>
      <td>{display_month(round.createdate)}</td>
      <td>{round.total_assignments}</td>
    </tr>
  );
};

export class Rounds extends Component {
  static propTypes = {
    rounds: PropTypes.array.isRequired,
    getRounds: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getRounds();
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Round</th>
                <th scope="col">Assigned</th>
                <th scope="col"># Homes</th>
              </tr>
            </thead>
            <tbody>
              {this.props.rounds.map((round) => (
                <RoundRow round={round} key={round.id} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rounds: state.rounds.rounds,
});

export default connect(mapStateToProps, { getRounds })(Rounds);
