import React, { Fragment } from 'react';
import Main from '../layout/Main';
import Header from '../layout/Header';
import Alerts from '../layout/Alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faM, faT } from '@fortawesome/free-solid-svg-icons';
import RedDot from '../../assets/red-dot.png';
import GreenDot from '../../assets/green-dot.png';
import YellowDot from '../../assets/yellow-dot.png';
import CurrentLocation from '../../assets/current-32.png';
import { Badge } from 'react-bootstrap';

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const HelpScreen = () => {
  return (
    <Fragment>
      <Header text="Help" back="back" />
      <Main>
        <Alerts />
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <td colSpan="2">
                      <h3 className="mt-2">Map markers</h3>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src={RedDot} />
                    </td>
                    <td>Not yet visited/Mailed</td>
                  </tr>
                  <tr>
                    <td>
                      <img src={GreenDot} />
                    </td>
                    <td>Visited/Mailed</td>
                  </tr>
                  <tr>
                    <td>
                      <img src={YellowDot} />
                    </td>
                    <td>Vacant/rental/bad address</td>
                  </tr>
                  <tr>
                    <td>
                      <img src={CurrentLocation} />
                    </td>
                    <td>Your current location</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h3 className="mt-2">Case Types</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span title="Mortgage" className="text-secondary">
                        <span className="fa-stack small">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="fa-stack-2x"
                          />
                          <FontAwesomeIcon
                            icon={faM}
                            className="fa-stack-1x fa-inverse"
                          />
                        </span>
                      </span>
                    </td>
                    <td>Mortgage</td>
                  </tr>
                  <tr>
                    <td>
                      <span title="Tax" className="text-secondary">
                        <span className="fa-stack small">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="fa-stack-2x"
                          />
                          <FontAwesomeIcon
                            icon={faT}
                            className="fa-stack-1x fa-inverse"
                          />
                        </span>
                      </span>
                    </td>
                    <td>Tax</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h3 className="mt-2">Red flags</h3>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Badge bg="danger" title="Possible senior">
                        S
                      </Badge>
                    </td>
                    <td>Possible senior</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Badge bg="danger" title="Possible reverse mortgage">
                        RM
                      </Badge>
                    </td>
                    <td>Possible reverse mortgage</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h3 className="mt-2">Icons for visited homes</h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      <span className="orsuccess">
                        <FontAwesomeIcon
                          icon="circle-check"
                          title="Spoke with owner"
                        />
                      </span>
                    </td>
                    <td>Spoke with owner/will call hotline</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      <span className="orsuccess">
                        <FontAwesomeIcon icon="user" title="Spoke with other" />
                      </span>
                    </td>
                    <td>Spoke with other person</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      <span className="text-secondary">
                        <FontAwesomeIcon
                          icon="file-circle-check"
                          title="Left materials"
                        />
                      </span>
                    </td>
                    <td>Left material</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      <span className="text-secondary">
                        <FontAwesomeIcon icon="house" title="Attempted visit" />
                      </span>
                    </td>
                    <td>Made attempt</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      <span className="text-secondary">
                        <FontAwesomeIcon icon="envelope" title="Sent mail" />
                      </span>
                    </td>
                    <td>Sent Mail</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      <span className="orinvalid">
                        <FontAwesomeIcon icon="ban" />
                      </span>
                    </td>
                    <td>Vacant/rental/bad address</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Badge
                        bg="secondary"
                        className="ms-1"
                        title="# of visits"
                      >
                        0
                      </Badge>
                    </td>
                    <td>0 visits recorded</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Badge
                        bg="secondary"
                        className="ms-1"
                        title="# of visits"
                      >
                        1
                      </Badge>
                    </td>
                    <td>1 visit recorded</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Badge
                        bg="secondary"
                        className="ms-1"
                        title="# of visits"
                      >
                        2
                      </Badge>
                    </td>
                    <td>2 visits recorded</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Badge
                        bg="secondary"
                        className="ms-1"
                        title="# of visits"
                      >
                        3
                      </Badge>
                    </td>
                    <td>3 visits recorded</td>
                  </tr>
                </tbody>
              </table>
              <p>
                If you have questions about using this app, call Jonathan Pyle
                at{' '}
                <a target="_webapp" href="tel:1-215-981-3843">
                  215-981-3843
                </a>
                .
              </p>
              <p>
                Additional instructions are available at{' '}
                <a target="_webapp" href="https://outreach.philalegal.org/help">
                  https://outreach.philalegal.org/help
                </a>
              </p>
              <h1>Sample Script</h1>
              <h2>If door is answered</h2>
              <p>
                Hello, my name is Katsi Miranda and I am with New Kensington
                CDC. (Show identification.) Am I speaking to [NAME OF
                HOMEOWNER]?
              </p>

              <h3>If speaking to homeowner</h3>

              <h4>Describe program benefits</h4>
              <p>
                You may be in danger of losing your home to foreclosure, but you
                still have a chance to save it. In order to save your home you
                should <strong>meet with a housing counselor</strong>.
              </p>

              <h4>Urge them to call the Hotline</h4>
              <p>
                To set up a meeting with a housing counselor, call 215-334-HOME
                (215-334-4663). The counselor will review your financial
                situation and help you apply for assistance.
              </p>

              <h4>Final reminder to call Hotline</h4>
              <p>
                Thank you for your time and please call 215-334-HOME.
                <br />
                [Optional, if you have a work-issued cell phone]
                <br />I can place the call with you right now if you would like.
              </p>

              <h3>When speaking to someone other than homeowner</h3>
              <p>
                When will [NAME OF HOMEOWNER] be home? Please give this flier to
                [the homeowner] and tell him [or her] to call 215-334-HOME.
                Thank you for your time.
              </p>

              <h3>When speaking to renter occupant</h3>
              <p>
                Does the owner reside in this property? [If yes, see “When
                speaking to someone other than homeowner” above; if no, continue
                below]
              </p>

              <p>
                If you are in contact with the owner, please share this flier
                with them. If you want to learn about your rights as a renter,
                you can contact the Hotline at (215) 334-HOME.
              </p>

              <h2>If no one is home:</h2>
              <p>Leave flier and record that the home was visited.</p>
            </div>
          </div>
        </div>
      </Main>
    </Fragment>
  );
};

export default HelpScreen;
