import {
  GET_REPORTS,
  GET_REPORT,
  LOADING_REPORT,
  GET_STATISTICS,
  LOADING_STATISTICS,
} from '../actions/types.js';

const initialState = {
  reports: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REPORT:
      var new_reports = [...state.reports];
      var n = new_reports.length;
      for (var i = 0; i < n; ++i) {
        if (new_reports[i].roundnum == action.payload.roundnum) {
          new_reports[i] = {
            ...new_reports[i],
            ...action.payload,
            loading: false,
          };
        }
      }
      return {
        ...state,
        reports: new_reports,
      };
    case LOADING_REPORT:
      var new_reports = [...state.reports];
      var n = new_reports.length;
      for (var i = 0; i < n; ++i) {
        if (new_reports[i].roundnum == action.roundnum) {
          new_reports[i] = { ...new_reports[i], loading: true };
        }
      }
      return {
        ...state,
        reports: new_reports,
      };
    case GET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case GET_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    case LOADING_STATISTICS:
      return state;
    default:
      return state;
  }
}
