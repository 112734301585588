import axios from 'axios';
import {
  GET_HOMES,
  GET_HOME,
  GET_HOME_AND_VISIT,
  UPDATE_HOME,
  UPDATE_STATUS,
  UPDATE_VISIT,
  DELETE_VISIT,
  UPDATE_ROUND,
  GET_VISIT,
} from './types';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

export const getHomes = (roundnum) => (dispatch, getState) => {
  const config = tokenConfig(getState);
  config['params'] = { roundnum: roundnum };
  axios
    .get('/api/assignments/', config)
    .then((res) => {
      dispatch({
        type: GET_HOMES,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
  dispatch({ type: UPDATE_ROUND, roundnum: roundnum });
};

export const getHome = (roundnum, indexno) => (dispatch, getState) => {
  const config = tokenConfig(getState);
  config['params'] = { roundnum: roundnum, indexno: indexno };
  axios
    .get('/api/assignment', config)
    .then((res) => {
      dispatch({
        type: GET_HOME,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
  dispatch({ type: UPDATE_ROUND, roundnum: roundnum });
};

export const getHomeAndVisit = (roundnum, indexno, visitno) => (
  dispatch,
  getState
) => {
  const config = tokenConfig(getState);
  config['params'] = { roundnum: roundnum, indexno: indexno, visitno: visitno };
  axios
    .get('/api/assignmentvisit', config)
    .then((res) => {
      dispatch({
        type: GET_HOME_AND_VISIT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
  dispatch({ type: UPDATE_ROUND, roundnum: roundnum });
};

export const writeInfo = (payload) => (dispatch, getState) => {
  axios
    .patch('/api/homeinfo', payload, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_HOME,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          status: 'errorUpdateHome',
        },
      })
    );
};

export const makeVisit = (payload) => (dispatch, getState) => {
  axios
    .post('/api/visit', payload, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_VISIT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const updateVisit = (payload) => (dispatch, getState) => {
  axios
    .patch('/api/visit', payload, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_VISIT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const deleteVisit = (id) => (dispatch, getState) => {
  const config = tokenConfig(getState);
  config['params'] = { id: id };
  axios
    .delete('/api/visit', config)
    .then((res) => {
      dispatch({
        type: DELETE_VISIT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const updateStatus = (newStatus, subStatus = null) => (
  dispatch,
  getState
) => {
  dispatch({
    type: UPDATE_STATUS,
    payload: {
      status: newStatus,
      subStatus: subStatus,
    },
  });
};

export const getVisit = (id) => (dispatch, getState) => {
  const config = tokenConfig(getState);
  config['params'] = { id: id };
  axios
    .get('/api/visit', config)
    .then((res) => {
      dispatch({
        type: GET_VISIT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
