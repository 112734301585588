import React, { Fragment, useEffect, useRef, useState } from 'react';
import { getUsers, getUser, updateUser } from '../../actions/users';
import { getAgencies } from '../../actions/agencies';
import { getReports } from '../../actions/reporting';
import { updateNav } from '../../actions/nav';
import Main from '../layout/Main';
import Header from '../layout/Header';
import Alerts from '../layout/Alerts';
import Rounds from '../rounds/Rounds';
import { AgencyMap } from '../agencies/AgencyMap';
import { ReportsTab } from '../report/ReportList';
import { UsersTab } from '../users/Users';
import { AgenciesTab } from '../agencies/Agencies';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Button, Nav } from 'react-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const AdminTab = (props) => {
  switch (props.tab) {
    case 'users':
      return <UsersTab />;
    case 'agencies':
      return <AgenciesTab />;
    default:
      return null;
  }
};

const AdminDashboard = () => {
  const auth = useSelector((state) => state.auth);
  const tab = useSelector((state) => state.nav.admin);
  const dispatch = useDispatch();
  const handleSelect = (newTab) => {
    dispatch(updateNav({ admin: newTab }));
  };
  return (
    <>
      <Nav fill variant="tabs" activeKey={tab} onSelect={handleSelect}>
        <Nav.Item>
          <Nav.Link eventKey="users">Users</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="agencies">Agencies</Nav.Link>
        </Nav.Item>
      </Nav>
      <AdminTab tab={tab} />
    </>
  );
};

const SuperDashboard = (props) => {
  const dispatch = useDispatch();
  const reporting = useSelector((state) => state.reporting);
  const agencies = useSelector((state) => state.agencies);
  const [agency_id, setAgencyID] = useState(null);
  useEffect(() => {
    dispatch(getAgencies());
  }, [dispatch]);
  useEffect(() => {
    if (agency_id) {
      dispatch(getReports(agency_id));
    }
  }, [dispatch, agency_id]);
  const agencyID = React.useRef(null);
  return (
    <div className="row">
      <div className="col-lg-6 offset-lg-3">
        <Form>
          <Form.Group className="mb-3" controlId="agency">
            <Form.Label>Agency</Form.Label>
            <Form.Select
              aria-label="Agency"
              ref={agencyID}
              onChange={(e) => {
                setAgencyID(agencyID.current.value);
              }}
            >
              <option>Choose one...</option>
              {agencies && agencies.list && agencies.list.length > 0
                ? agencies.list
                    .filter((agency) => {
                      if (agency.role.name != 'outreach') {
                        return false;
                      }
                      var found = false;
                      var n = props.oversees.length;
                      if (n == 0) {
                        return true;
                      }
                      for (var i = 0; i < n; ++i) {
                        if (props.oversees[i].id == agency.id) {
                          found = true;
                          break;
                        }
                      }
                      return found;
                    })
                    .map((agency) => (
                      <option key={'agency' + agency.id} value={agency.id}>
                        {agency.name}
                      </option>
                    ))
                : null}
            </Form.Select>
          </Form.Group>
        </Form>
        <div>
          {agency_id ? (
            <>
              <AgencyMap agency={agency_id} />
              {reporting &&
              reporting.reports &&
              reporting.reports.length > 0 ? (
                <ReportsTab reporting={reporting} agency_id={agency_id} />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default function Dashboard() {
  const auth = useSelector((state) => state.auth);
  return (
    <Fragment>
      <Header text="Outreach" />
      <Alerts />
      <Main>
        <div className="container pt-2">
          {auth.user.is_admin ? (
            <AdminDashboard />
          ) : auth.user.is_super ? (
            <SuperDashboard oversees={auth.user.oversees} />
          ) : (
            <Rounds />
          )}
        </div>
      </Main>
    </Fragment>
  );
}
