import axios from 'axios';
import { GET_ROUNDS, DELETE_ROUND, ADD_ROUND } from './types';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

// getRounds

export const getRounds = () => (dispatch, getState) => {
  axios
    .get('/api/rounds/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ROUNDS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const deleteRound = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/rounds/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteRound: 'Round deleted' }));
      dispatch({
        type: DELETE_ROUND,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

export const addRound = (round) => (dispatch, getState) => {
  axios
    .post('/api/rounds/', round, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addRound: 'Round added' }));
      dispatch({
        type: ADD_ROUND,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
