import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  getUsers,
  getUser,
  updateUser,
  newUser,
  changePassword,
} from '../../actions/users';
import { loadUser } from '../../actions/auth';
import { getAgencies } from '../../actions/agencies';
import { displayMessage } from '../../actions/alerts';
import { textValidate, emailValidate } from '../../actions/functions';
import Main from '../layout/Main';
import Header from '../layout/Header';
import Alerts from '../layout/Alerts';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Button } from 'react-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const UserNew = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isPrimary = React.useRef(null);
  const firstName = React.useRef(null);
  const lastName = React.useRef(null);
  const password = React.useRef(null);
  const passwordTwo = React.useRef(null);
  const email = React.useRef(null);
  const agencyID = React.useRef(null);
  const agencies = useSelector((state) => state.agencies);
  useEffect(() => {
    dispatch(getAgencies());
  }, [dispatch]);
  return (
    <Fragment>
      <Header text="New user" back="#/" />
      <Alerts />
      <Main>
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h3>Add new user</h3>
              <Form
                onSubmit={(e) => {
                  var payload = {
                    email: email.current.value,
                    first_name: firstName.current.value,
                    last_name: lastName.current.value,
                    password: password.current.value,
                    agency_id: agencyID.current.value,
                    is_primary: isPrimary.current.checked,
                  };
                  e.preventDefault();
                  if (password.current.value != passwordTwo.current.value) {
                    dispatch(
                      displayMessage('The passwords do not match.', 'warning')
                    );
                  } else {
                    dispatch(newUser(payload, navigate, setLoading));
                    setLoading(true);
                  }
                  return false;
                }}
              >
                <Form.Group className="mb-3" controlId="isPrimary">
                  <Form.Switch
                    label="Primary outreach coordinator for agency"
                    ref={isPrimary}
                    value="on"
                    defaultChecked={false}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label>First name</Form.Label>
                  <Form.Control type="text" ref={firstName} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lastName">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control type="text" ref={lastName} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" ref={email} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="agency">
                  <Form.Label>Agency</Form.Label>
                  <Form.Select aria-label="Agency" ref={agencyID}>
                    <option>Choose one...</option>
                    {agencies && agencies.list && agencies.list.length > 0
                      ? agencies.list.map((agency) =>
                          agency.is_active ? (
                            <option
                              key={'agency' + agency.id}
                              value={agency.id}
                            >
                              {agency.name}
                            </option>
                          ) : null
                        )
                      : null}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Enter Password</Form.Label>
                  <Form.Control type="password" ref={password} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="passwordTwo">
                  <Form.Label>Enter Password Again</Form.Label>
                  <Form.Control type="password" ref={passwordTwo} />
                </Form.Group>
                {loading ? (
                  <Button variant="primary" disabled>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{' '}
                    Creating...
                  </Button>
                ) : (
                  <Button variant="primary" type="submit">
                    Create
                  </Button>
                )}
              </Form>
            </div>
          </div>
        </div>
      </Main>
    </Fragment>
  );
};

export const UserEdit = (props) => {
  const user = useSelector((state) => state.users.user);
  const agencies = useSelector((state) => state.agencies);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let { userid } = useParams();
  useEffect(() => {
    dispatch(getAgencies());
    if (userid) {
      dispatch(getUser(userid));
    }
  }, [dispatch, userid]);
  const isActive = React.useRef(null);
  const isPrimary = React.useRef(null);
  const firstName = React.useRef(null);
  const lastName = React.useRef(null);
  const email = React.useRef(null);
  const agencyID = React.useRef(null);
  const overseesID = React.useRef(null);
  return (
    <Fragment>
      <Header
        text={user && user.id ? user.first_name + ' ' + user.last_name : ''}
        back="#/"
      />
      <Alerts />
      <Main>
        {user && user.id ? (
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h3>Edit user</h3>
                <Form
                  onSubmit={(e) => {
                    if (!emailValidate(email.current.value)) {
                      e.preventDefault();
                      dispatch(
                        displayMessage(
                          'The email address is not valid.',
                          'danger'
                        )
                      );
                      return false;
                    }
                    if (!textValidate(firstName.current.value)) {
                      e.preventDefault();
                      dispatch(
                        displayMessage('You must enter a first name.', 'danger')
                      );
                      return false;
                    }
                    if (!textValidate(lastName.current.value)) {
                      e.preventDefault();
                      dispatch(
                        displayMessage('You must enter a last name.', 'danger')
                      );
                      return false;
                    }
                    if (!agencyID.current.value) {
                      e.preventDefault();
                      dispatch(
                        displayMessage('You must select an agency.', 'danger')
                      );
                      return false;
                    }
                    var payload = {
                      id: user.id,
                      email: email.current.value,
                      first_name: firstName.current.value,
                      last_name: lastName.current.value,
                      agency_id: agencyID.current.value,
                      is_active: isActive.current.checked,
                      in_profile: false,
                    };
                    if (user.agency.role.name == 'supervisor') {
                      payload.oversees_id = Array.prototype.slice
                        .call(overseesID.current.selectedOptions)
                        .map((option) => {
                          return option.value;
                        });
                      payload.is_primary = false;
                    } else {
                      payload.oversees_id = [];
                      payload.is_primary = isPrimary.current.checked;
                    }
                    e.preventDefault();
                    dispatch(updateUser(payload, navigate, setLoading));
                    setLoading(true);
                    return false;
                  }}
                >
                  <Form.Group className="mb-3" controlId="isActive">
                    <Form.Switch
                      label="Active"
                      ref={isActive}
                      value="on"
                      defaultChecked={user.is_active}
                    />
                  </Form.Group>
                  {user.agency.role.name != 'supervisor' ? (
                    <Form.Group className="mb-3" controlId="isPrimary">
                      <Form.Switch
                        label="Primary outreach coordinator for agency"
                        ref={isPrimary}
                        value="on"
                        defaultChecked={user.is_primary}
                      />
                    </Form.Group>
                  ) : null}
                  <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      ref={firstName}
                      defaultValue={user.first_name}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      ref={lastName}
                      defaultValue={user.last_name}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      ref={email}
                      defaultValue={user.email}
                    />
                  </Form.Group>
                  {user.agency &&
                  user.agency.id &&
                  agencies &&
                  agencies.list &&
                  agencies.list.length > 0 ? (
                    <Form.Group className="mb-3" controlId="agency">
                      <Form.Label>Agency</Form.Label>
                      <Form.Select
                        aria-label="Agency"
                        ref={agencyID}
                        defaultValue={user.agency.id}
                      >
                        <option value="">Choose one...</option>
                        {agencies.list.map((agency) =>
                          agency.id == user.agency.id || agency.is_active ? (
                            <option
                              key={'agency' + agency.id}
                              value={agency.id}
                            >
                              {agency.name}
                            </option>
                          ) : null
                        )}
                      </Form.Select>
                    </Form.Group>
                  ) : null}
                  {user.agency.role.name == 'supervisor' ? (
                    <div className="mb-3">
                      <label htmlFor="oversees">Oversees</label>
                      {(agencies && agencies.list && agencies.list.length) >
                      0 ? (
                        <select
                          ref={overseesID}
                          id="oversees"
                          className="form-select"
                          aria-label="Oversees"
                          defaultValue={agencies.list
                            .filter((agency) => {
                              var found = false;
                              var n = user.oversees.length;
                              for (var i = 0; i < n; ++i) {
                                if (agency.id == user.oversees[i].id) {
                                  found = true;
                                  break;
                                }
                              }
                              return found;
                            })
                            .map((agency) => {
                              return agency.id;
                            })}
                          multiple
                        >
                          {agencies.list.map((agency) => (
                            <option
                              key={'oversees' + agency.id}
                              value={agency.id}
                            >
                              {agency.name}
                            </option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  ) : null}
                  {loading ? (
                    <Button variant="primary" disabled>
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{' '}
                      Saving...
                    </Button>
                  ) : (
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        ) : null}
      </Main>
    </Fragment>
  );
};

export const UserProfile = (props) => {
  const user = useSelector((state) => state.users.user);
  const currentuser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const firstName = React.useRef(null);
  const lastName = React.useRef(null);
  const email = React.useRef(null);
  useEffect(() => {
    dispatch(getUser(currentuser.id));
  }, [dispatch, currentuser]);
  return (
    <Fragment>
      <Header text="Profile" back="#/" />
      <Alerts />
      <Main>
        {user && user.id ? (
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h3>User profile</h3>
                <div className="text-end mt-1">
                  <Link to="/password" className="btn btn-success">
                    Change password
                  </Link>
                </div>
                <Form
                  onSubmit={(e) => {
                    var payload = {
                      id: user.id,
                      email: email.current.value,
                      first_name: firstName.current.value,
                      last_name: lastName.current.value,
                      in_profile: true,
                    };
                    e.preventDefault();
                    dispatch(updateUser(payload, navigate, setLoading));
                    setLoading(true);
                    return false;
                  }}
                >
                  <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      ref={firstName}
                      defaultValue={user.first_name}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      ref={lastName}
                      defaultValue={user.last_name}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      ref={email}
                      defaultValue={user.email}
                    />
                  </Form.Group>
                  {loading ? (
                    <Button variant="primary" disabled>
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{' '}
                      Saving...
                    </Button>
                  ) : (
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        ) : null}
      </Main>
    </Fragment>
  );
};

export const Password = (props) => {
  const currentuser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const passwordOld = React.useRef(null);
  const password = React.useRef(null);
  const passwordTwo = React.useRef(null);
  return (
    <Fragment>
      <Header text="Change Password" back="#/user/profile" />
      <Alerts />
      <Main>
        {currentuser && currentuser.id ? (
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h3>Change password</h3>
                <Form
                  onSubmit={(e) => {
                    var payload = {
                      old_password: passwordOld.current.value,
                      new_password: password.current.value,
                    };
                    e.preventDefault();
                    if (password.current.value != passwordTwo.current.value) {
                      console.log('No match');
                      dispatch(
                        displayMessage('The passwords do not match.', 'warning')
                      );
                    } else {
                      console.log('It was a match.');
                      dispatch(changePassword(payload, setLoading));
                      setLoading(true);
                    }
                    return false;
                  }}
                >
                  <Form.Group className="mb-3" controlId="passwordOld">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control type="password" ref={passwordOld} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" ref={password} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="passwordTwo">
                    <Form.Label>Enter Again</Form.Label>
                    <Form.Control type="password" ref={passwordTwo} />
                  </Form.Group>
                  {loading ? (
                    <Button variant="primary" disabled>
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{' '}
                      Changing...
                    </Button>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Change
                      </Button>
                      <Link
                        to="/user/profile"
                        className="btn btn-secondary ms-1"
                      >
                        Cancel
                      </Link>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        ) : null}
      </Main>
    </Fragment>
  );
};

const UserRow = (props) => {
  const navigate = useNavigate();
  return (
    <tr
      style={{ cursor: 'pointer' }}
      onClick={(event) => {
        event.preventDefault();
        navigate('/users/' + props.user.id);
        return false;
      }}
    >
      <td>
        {props.user.is_active ? (
          props.user.is_primary ? (
            <span className="text-primary">
              <FontAwesomeIcon
                icon="star"
                title="Primary outreach coordinator"
              />
            </span>
          ) : (
            ''
          )
        ) : (
          <span className="orinvalid">
            <FontAwesomeIcon icon="ban" title="Account is disabled" />
          </span>
        )}
      </td>
      <td>{props.user.agency.name}</td>
      <td>{props.user.first_name + ' ' + props.user.last_name}</td>
      <td>{props.user.email}</td>
    </tr>
  );
};

export const UsersTab = () => {
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  return users && users.list && users.list.length > 0 ? (
    <div className="row">
      <div className="col-lg-10 offset-lg-1">
        <div className="text-end mt-1">
          <Link to="/useradd" className="btn btn-success">
            <FontAwesomeIcon icon="plus" /> Add user
          </Link>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Agency</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
            </tr>
          </thead>
          <tbody>
            {users.list.map((user) => (
              <UserRow user={user} key={user.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
};
