import {
  GET_AGENCIES,
  GET_AGENCY,
  GET_AGENCY_MAP,
  NEW_AGENCY,
  UPDATE_AGENCY,
  GET_ROLES,
  GET_TRACTS,
} from '../actions/types.js';

const initialState = {
  agency: null,
  list: [],
  roles: [],
  tracts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AGENCIES:
      return {
        ...state,
        list: action.payload,
        agency: null,
      };
    case GET_AGENCY:
      return {
        ...state,
        agency: action.payload,
      };
    case GET_AGENCY_MAP:
      return {
        ...state,
        polygons: action.payload.polygons,
        centroid: action.payload.centroid,
      };
    case NEW_AGENCY:
    case UPDATE_AGENCY:
      return {
        ...state,
        agency: null,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_TRACTS:
      return {
        ...state,
        tracts: action.payload,
      };
    default:
      return state;
  }
}
