import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { useSelector, useDispatch } from 'react-redux';
import { getAgencyMap } from '../../actions/agencies';
import { GET_AGENCY_MAP } from '../../actions/types';

const render = (status) => {
  return <div style={{ width: '100%', height: '600px' }} />;
};

const AgencyMapComponent = (props) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && !props.themap) {
      props.setMap(
        new window.google.maps.Map(ref.current, {
          zoom: 13,
          center: props.center,
        })
      );
    }
    return () => {
      if (props.themap) {
        props.setMap(null);
      }
    };
  }, [ref, props.themap]);
  const mapStyle = { width: '100%', height: '600px' };
  return (
    <>
      <div style={mapStyle} ref={ref} />
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, props.themap);
        }
      })}
    </>
  );
};

const MyMarker = (options) => {
  const [marker, setMarker] = useState();
  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      console.log('setting marker options');
      var theOptions = {
        title: options.title,
        icon: options.icon,
        position: options.position,
      };
      console.log(theOptions);
      marker.setOptions(theOptions);
      marker.setMap(options.themap);
      if (options.onClick) {
        google.maps.event.clearListeners(marker, 'click');
        marker.addListener('click', options.onClick);
      }
    }
  }, [marker, options]);
  return null;
};

const Polygon = (params) => {
  const [polygon, setPolygon] = useState();
  const [infoWindow, setInfoWindow] = useState();
  useEffect(() => {
    if (!polygon) {
      setPolygon(new google.maps.Polygon());
    }
    return () => {
      if (polygon) {
        polygon.setMap(null);
      }
    };
  }, [polygon]);
  useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(new google.maps.InfoWindow());
    }
    return () => {
      if (infoWindow) {
        infoWindow.setMap(null);
      }
    };
  }, [infoWindow]);
  useEffect(() => {
    if (polygon && infoWindow) {
      polygon.setOptions({
        paths: params.polygon.coords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      });
      var themap = params.themap;
      polygon.setMap(themap);
      infoWindow.setOptions({
        content: 'Census tract ' + params.polygon.name,
        ariaLabel: 'Census tract ' + params.polygon.name,
        position: params.polygon.center,
      });
      google.maps.event.clearListeners(polygon, 'click');
      polygon.addListener('click', () => {
        infoWindow.open(themap);
      });
    }
  }, [polygon, infoWindow]);
  return null;
};

export const NullMap = (props) => {
  const mapStyle = { width: '100%', height: '600px' };
  return <div style={mapStyle} />;
};

export const AgencyMap = (props) => {
  const [themap, setMap] = useState();
  const geo = useSelector((state) => state.geo);
  const polygons = useSelector((state) => state.agencies.polygons);
  const center = useSelector((state) => state.agencies.centroid);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: GET_AGENCY_MAP,
      payload: {},
    });
    dispatch(getAgencyMap(props.agency));
  }, [dispatch, props.agency]);
  return (
    <>
      {polygons && polygons.length > 0 ? (
         <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} render={render}>
           <AgencyMapComponent center={center} themap={themap} setMap={setMap} agency={props.agency}>
             {polygons.map((polygon) => (
               <Polygon
                 key={'polygon' + polygon.indexno}
                 polygon={polygon}
                 themap={themap}
               />
             ))}
             {false && geo.coords && geo.coords.latitude ? (
		<MyMarker
                  key="userlocation"
                  title="Your location"
                  icon={CurrentLocation}
                  position={{
                    lat: geo.coords.latitude,
                    lng: geo.coords.longitude,
                  }}
                  themap={themap}
		/>
             ) : null}
           </AgencyMapComponent>
         </Wrapper>
      ) : <NullMap />}
    </>
  );
};
