import React, { Component, Fragment } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import Main from '../layout/Main';
import Alerts from '../layout/Alerts';
import { Form, Button } from 'react-bootstrap';

export class Login extends Component {
  state = {
    email: '',
    password: '',
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.email, this.state.password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      return <Navigate replace to="/" />;
    }
    const { email, password } = this.state;
    return (
      <>
        <Alerts />
        <Main>
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <Alerts />
                <div className="card mt-5">
                  <div className="card-body">
                    <h2 className="text-center">Log in</h2>
                    <Form onSubmit={this.onSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          onChange={this.onChange}
                          value={email}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          onChange={this.onChange}
                          value={password}
                        />
                      </Form.Group>

                      <Button type="submit" variant="primary">
                        Login
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
