import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  displayError,
  displayMessage,
  hideMessage,
} from '../../actions/alerts';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function AlertDismissibleExample() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
          Change this and that and try again. Duis mollis, est non commodo
          luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
          Cras mattis consectetur purus sit amet fermentum.
        </p>
      </Alert>
    );
  }
  return <Button onClick={() => setShow(true)}>Show Alert</Button>;
}

export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  closeMessage = (e) => {
    this.props.hideMessage();
  };

  componentDidUpdate(prevProps) {
    const { error, message } = this.props;
    /* console.log('componentDidUpdate');
     * console.log(error);
     * console.log(message); */
    if (error !== prevProps.error) {
      if (error.msg.agency_id) {
        this.props.displayError(`Agency: ${error.msg.agency_id.join()}`);
      }
      if (error.msg.email) {
        this.props.displayError(`Email: ${error.msg.email.join()}`);
      }
      if (error.msg.password) {
        this.props.displayError(`Password: ${error.msg.password.join()}`);
      }
      if (error.msg.old_password) {
        this.props.displayError(
          `Old Password: ${error.msg.old_password.join()}`
        );
      }
      if (error.msg.new_password) {
        this.props.displayError(
          `New Password: ${error.msg.new_password.join()}`
        );
      }
      if (error.msg.round_start) {
        this.props.displayError(`Start Round: ${error.msg.round_start.join()}`);
      }
      if (error.msg.round_end) {
        this.props.displayError(`End Round: ${error.msg.round_end.join()}`);
      }
      if (error.msg.report_type) {
        this.props.displayError(`Report Type: ${error.msg.report_type.join()}`);
      }
      if (error.msg.roundnum) {
        this.props.displayError(`Round number: ${error.msg.roundnum.join()}`);
      }
      if (error.msg.createdate) {
        this.props.displayError(`Create date: ${error.msg.createdate.join()}`);
      }
      if (error.msg.message) {
        this.props.displayError(`Message: ${error.msg.message}`);
      }
      if (error.msg.non_field_errors) {
        this.props.displayError(error.msg.non_field_errors.join());
      }
      if (error.msg.username) {
        this.props.displayError(error.msg.username.join());
      }
    }

    if (message != prevProps.message) {
      if (message.deleteRound) {
        this.props.displayMessage(message.deleteRound, 'success');
      }
      if (message.addRound) {
        this.props.displayMessage(message.addRound, 'success');
      }
      if (message.passwordsNotMatch) {
        this.props.displayError(message.passwordsNotMatch);
      }
    }
  }
  render() {
    if (this.props.alerts.messageShown) {
      return (
        <Alert
          className="fixed-top"
          variant={this.props.alerts.messageColor}
          onClose={() => this.closeMessage()}
          dismissible
        >
          {this.props.alerts.messageToDisplay}
        </Alert>
      );
    } else {
      return <Fragment />;
    }
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
  alerts: state.alerts,
});

export default connect(mapStateToProps, {
  displayError,
  displayMessage,
  hideMessage,
})(Alerts);
