import React from 'react';
import Home from './Home';

import { useParams } from 'react-router-dom';

export const HomeScreen = () => {
  let { roundnum, homenum } = useParams();
  return <Home roundnum={roundnum} homenum={homenum} />;
};

export default HomeScreen;
