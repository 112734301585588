import { DISPLAY_MESSAGE, HIDE_MESSAGE } from '../actions/types';

const initialState = {
  messageToDisplay: '',
  messageColor: '',
  messageShown: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DISPLAY_MESSAGE:
      return (state = { ...action.payload, messageShown: true });
    case HIDE_MESSAGE:
      return (state = {
        messageToDisplay: '',
        messageColor: '',
        messageShown: false,
      });
    default:
      return state;
  }
}
