import React, { Component, Fragment, useEffect } from 'react';
import { connect, compose, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getHomes } from '../../actions/homes';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from '../layout/Header';
import Alerts from '../layout/Alerts';
import Main from '../layout/Main';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'react-bootstrap';
import CurrentLocation from '../../assets/current-32.png';
import { faCircle, faM, faT } from '@fortawesome/free-solid-svg-icons';

function describeVisits(visits) {
  if (visits === 0) {
    return 'No visits yet';
  }
  if (visits === 1) {
    return '1 visit has been made';
  }
  return visits + ' have been made';
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const HomeIcon = (props) => {
  switch (props.home.status) {
    case 'owner':
      return (
        <span className="orsuccess">
          <FontAwesomeIcon icon="circle-check" title="Spoke with owner" />
        </span>
      );
    case 'other':
      return (
        <span className="orsuccess">
          <FontAwesomeIcon icon="user" title="Spoke with other" />
        </span>
      );
    case 'materials':
      return (
        <>
          <span className="text-secondary">
            <FontAwesomeIcon icon="file-circle-check" title="Left materials" />
          </span>
          <Badge
            bg="secondary"
            className="ms-1"
            title={describeVisits(props.home.visits)}
          >
            {props.home.visits}
          </Badge>
        </>
      );
    case 'attempted':
      return (
        <>
          <span className="text-secondary">
            <FontAwesomeIcon icon="house" title="Attempted visit" />
          </span>
          <Badge
            bg="secondary"
            className="ms-1"
            title={describeVisits(props.home.visits)}
          >
            {props.home.visits}
          </Badge>
        </>
      );
    case 'vacant':
      return (
        <span className="orinvalid">
          <FontAwesomeIcon icon="ban" title="Vacant" />
        </span>
      );
    case 'rental':
      return (
        <span className="orinvalid">
          <FontAwesomeIcon icon="ban" title="Rental" />
        </span>
      );
    case 'bad':
      return (
        <span className="orinvalid">
          <FontAwesomeIcon icon="ban" title="Bad address" />
        </span>
      );
    case 'sentmail':
      return (
        <>
          <span className="text-secondary">
            <FontAwesomeIcon icon="envelope" title="Sent mail" />
          </span>
          <Badge
            bg="secondary"
            className="ms-1"
            title={describeVisits(props.home.visits)}
          >
            {props.home.visits}
          </Badge>
        </>
      );
    default:
      return null;
  }
};

function describeHome(home) {
  return home.numberprefixstreettype;
}

function iconFor(home) {
  var color;
  if (home.vacant || home.rental || home.badaddress) {
    color = 'yellow';
  } else if (
    (home.comments && home.comments !== '') ||
    home.phone ||
    (home.hotline && home.hotline !== 'na') ||
    home.visits > 0
  ) {
    color = 'green';
  } else {
    color = 'red';
  }
  return {
    url: 'https://maps.google.com/mapfiles/ms/icons/' + color + '-dot.png',
  };
}

const render = (status) => {
  return <div style={{ width: '100%', height: '300px' }} />;
};

export const MyMapComponent = (props) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();
  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoom: 13,
          center: props.center,
        })
      );
    }
  }, [ref, map]);
  const mapStyle = { width: '100%', height: '300px' };
  return (
    <>
      <div style={mapStyle} ref={ref} />
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const MyMarker = (options) => {
  const [marker, setMarker] = React.useState();
  /* const onClick = (e) => {
   *   console.log('on click marker');
   * };
   * const onIdle = (m) => {
   *   console.log('onIdle marker');
   * }; */
  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      if (options.onClick) {
        google.maps.event.clearListeners(marker, 'click');
        marker.addListener('click', options.onClick);
      }
    }
  }, [marker, options]);
  /* useEffect(() => {
   *   if (marker) {
   *     ['click', 'idle'].forEach((eventName) =>
   *       google.maps.event.clearListeners(marker, eventName)
   *     );
   *     if (onClick) {
   *       marker.addListener('click', onClick);
   *     }

   *     if (onIdle) {
   *       marker.addListener('idle', () => onIdle(marker));
   *     }
   *   }
   * }, [marker, onClick, onIdle]); */
  /* useDeepCompareEffectForMaps(() => {
   *   if (marker) {
   *     marker.setOptions(options);
   *   }
   * }, [marker, options]); */
  return null;
};

export const NullMap = (props) => {
  const mapStyle = { width: '100%', height: '300px' };
  return <div style={mapStyle} />;
};

export const MyMap = (props) => {
  let { roundnum } = useParams();
  const navigate = useNavigate();
  const geo = useSelector((state) => state.geo);
  var n = props.homes.length;
  var avgLat = 0;
  var avgLon = 0;
  for (var i = 0; i < n; ++i) {
    if (props.homes[i].latitude && props.homes[i].longitude) {
      avgLat = avgLat + props.homes[i].latitude;
      avgLon = avgLon + props.homes[i].longitude;
    }
  }
  avgLat = avgLat / n;
  avgLon = avgLon / n;
  if (Number.isNaN(avgLat) || avgLat == 0) {
    avgLat = 39.9526;
    avgLon = -75.1652;
  }
  const center = {
    lat: avgLat,
    lng: avgLon,
  };
  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} render={render}>
      {props.homes.length > 0 ? (
        <MyMapComponent center={center}>
          {props.homes.map((home) => (
            <MyMarker
              key={'marker' + home.id}
              title={describeHome(home)}
              icon={iconFor(home)}
              onClick={(event) => {
                navigate('/home/' + roundnum + '/' + home.indexno);
              }}
              position={{
                lat: home.latitude,
                lng: home.longitude,
              }}
            />
          ))}
          {geo.coords && geo.coords.latitude ? (
            <MyMarker
              key="userlocation"
              title="Your location"
              icon={CurrentLocation}
              position={{
                lat: geo.coords.latitude,
                lng: geo.coords.longitude,
              }}
            />
          ) : null}
        </MyMapComponent>
      ) : (
        <div style={{ width: '100%', height: '300px' }} />
      )}
    </Wrapper>
  );
};

export const Homes = () => {
  const homes = useSelector((state) => state.homes.homes);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { roundnum } = useParams();
  useEffect(() => {
    dispatch(getHomes(roundnum));
  }, [dispatch]);
  return (
    <Fragment>
      <Header text={'Round ' + roundnum} back="#/" />
      <Main>
        <MyMap homes={homes} />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <Alerts />
              <table className="table table-hover mt-3">
                <tbody>
                  {homes.map((home) => (
                    <tr
                      key={home.id}
                      style={{ cursor: 'pointer' }}
                      onClick={(event) => {
                        navigate('/home/' + roundnum + '/' + home.indexno);
                      }}
                    >
                      <td>{home.indexno}.</td>
                      <td>
                        <span
                          title={capitalize(home.casetype)}
                          className="text-secondary"
                        >
                          <span className="fa-stack small">
                            <FontAwesomeIcon
                              icon={faCircle}
                              className="fa-stack-2x"
                            />
                            <FontAwesomeIcon
                              icon={home.casetype == 'mortgage' ? faM : faT}
                              className="fa-stack-1x fa-inverse"
                            />
                          </span>
                          {}
                        </span>
                      </td>
                      <td>
                        <HomeIcon home={home} />
                      </td>
                      <td>{home.numberprefixstreettype}</td>
                      <td>
                        {home.senior ? (
                          <Badge
                            bg="danger"
                            className="me-1"
                            title="Possible senior"
                          >
                            S
                          </Badge>
                        ) : (
                          ''
                        )}
                        {home.isreverse ? (
                          <Badge
                            bg="danger"
                            className="me-1"
                            title="Possible reverse mortgage"
                          >
                            RM
                          </Badge>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Main>
    </Fragment>
  );
};

export default Homes;
