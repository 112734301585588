import React from 'react';
import Visit from './Visit';

import { useParams } from 'react-router-dom';

export const VisitScreen = (props) => {
  let { roundnum, homenum, visitno } = useParams();
  return <Visit roundnum={roundnum} homenum={homenum} visitno={visitno} />;
};

export default VisitScreen;
