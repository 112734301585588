import {
  GET_HOMES,
  GET_HOME,
  GET_HOME_AND_VISIT,
  UPDATE_HOME,
  UPDATE_STATUS,
  UPDATE_VISIT,
  DELETE_VISIT,
  UPDATE_ROUND,
  GET_VISIT,
} from '../actions/types.js';

const initialState = {
  homes: [],
  home: { indexno: null, info: {}, visits: [] },
  status: 'ok',
  subStatus: null,
  roundnum: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOMES:
      return {
        ...state,
        home: { indexno: null, info: {}, visits: [] },
        homes: action.payload,
        status: 'ok',
      };
    case GET_HOME:
      return {
        ...state,
        home: action.payload,
        visit: 'null',
        status: 'ok',
      };
    case GET_HOME_AND_VISIT:
      return {
        ...state,
        home: action.payload.home,
        visit: action.payload.visit,
        status: 'ok',
      };
    case UPDATE_HOME:
      return {
        ...state,
        status: 'saved',
      };
    case UPDATE_VISIT:
      return {
        ...state,
        home: {
          ...state.home,
          visits: action.payload,
        },
        status: 'saved',
      };
    case DELETE_VISIT:
      return {
        ...state,
        home: {
          ...state.home,
          visits: action.payload,
        },
        visit: 'deleted',
        status: 'ok',
      };
    case UPDATE_STATUS:
      return {
        ...state,
        status: action.payload.status,
        subStatus: action.payload.subStatus,
      };
    case GET_VISIT:
      return {
        ...state,
        visit: action.payload,
        status: 'ok',
      };
    case UPDATE_ROUND:
      return {
        ...state,
        roundnum: action.roundnum,
        status: 'ok',
      };
    default:
      return state;
  }
}
