import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect, compose, useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Main from '../layout/Main';
import Header from '../layout/Header';
import Alerts from '../layout/Alerts';
import { getReports, getReport, getStatistics } from '../../actions/reporting';
import { Form, Badge, Button, Nav } from 'react-bootstrap';
import { display_month } from '../rounds/Rounds';
import { getRounds } from '../../actions/rounds';
import { faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActiveTab = (props) => {
  const auth = useSelector((state) => state.auth);
  switch (props.tab) {
    case 'reports':
      return (
        <ReportsTab
          reporting={props.reporting}
          agency_id={auth.user.agency.id}
        />
      );
    case 'planning':
      return <PlanningTab reporting={props.reporting} />;
    case 'statistics':
      return <StatisticsTab reporting={props.reporting} />;
    default:
      return null;
  }
};

export const ReportsTab = (props) => {
  const dispatch = useDispatch();
  return props.reporting.reports && props.reporting.reports.length > 0 ? (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Round</th>
          <th scope="col">Month</th>
          <th scope="col" style={{ width: '150px' }}>
            Report
          </th>
        </tr>
      </thead>
      <tbody>
        {props.reporting.reports.map((report) => (
          <tr key={'round' + report.roundnum}>
            <td>{report.roundnum}</td>
            <td>{display_month(report.createdate)}</td>
            <td style={{ width: '150px' }}>
              {report.url ? (
                <a className="btn btn-info btn-sm" href={report.url}>
                  <FontAwesomeIcon icon={faDownload} /> Download
                </a>
              ) : report.loading ? (
                <Button variant="light" size="sm" disabled>
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{' '}
                  Generating...
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() =>
                    dispatch(
                      getReport({
                        roundnum: report.roundnum,
                        agency_id: props.agency_id,
                      })
                    )
                  }
                  size="sm"
                >
                  Generate
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : null;
};

const StatisticsTab = (props) => {
  const dispatch = useDispatch();
  const rounds = useSelector((state) => state.rounds);
  const reporting = useSelector((state) => state.reporting);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('reports');
  useEffect(() => {
    dispatch(getRounds());
  }, [dispatch, getRounds]);
  const roundStart = React.useRef(null);
  const roundEnd = React.useRef(null);
  const reportType = React.useRef(null);
  return (
    <>
      <h3 className="mt-3">Statistics</h3>
      <Form
        onSubmit={(e) => {
          var payload = {
            round_start: roundStart.current.value,
            round_end: roundEnd.current.value,
            report_type: reportType.current.value,
          };
          e.preventDefault();
          dispatch(getStatistics(payload, setLoading));
          setLoading(true);
          return false;
        }}
      >
        <Form.Group className="mb-3" controlId="round_start">
          <Form.Label>Start Round</Form.Label>
          <Form.Select aria-label="Start Round" ref={roundStart}>
            <option>Choose one...</option>
            {rounds && rounds.rounds && rounds.rounds.length > 0
              ? rounds.rounds.map((round) => (
                  <option
                    key={'startround' + round.roundnum}
                    value={round.roundnum}
                  >
                    {display_month(round.createdate)}
                  </option>
                ))
              : null}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="round_end">
          <Form.Label>End Round</Form.Label>
          <Form.Select aria-label="End Round" ref={roundEnd}>
            <option>Choose one...</option>
            {rounds && rounds.rounds && rounds.rounds.length > 0
              ? rounds.rounds.map((round) => (
                  <option
                    key={'endround' + round.roundnum}
                    value={round.roundnum}
                  >
                    {display_month(round.createdate)}
                  </option>
                ))
              : null}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="report_type">
          <Form.Label>Report Type</Form.Label>
          <Form.Select aria-label="Report Type" ref={reportType}>
            <option>Choose one...</option>
            <option key="mortgage" value="mortgage">
              Mortgage
            </option>
            <option key="tax" value="tax">
              Tax
            </option>
            <option key="both" value="both">
              Both
            </option>
          </Form.Select>
        </Form.Group>
        {loading ? (
          <Button className="mb-3" variant="primary" disabled>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{' '}
            Calculating...
          </Button>
        ) : (
          <Button className="mb-3" variant="primary" type="submit">
            Calculate
          </Button>
        )}
      </Form>
      {reporting && reporting.statistics ? (
        <div
          dangerouslySetInnerHTML={{ __html: reporting.statistics.report }}
        ></div>
      ) : null}
    </>
  );
};

const PlanningTab = (props) => {
  return props.reporting.reports && props.reporting.reports.length > 0 ? (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Round</th>
          <th scope="col">Month</th>
          <th scope="col">Planning list</th>
        </tr>
      </thead>
      <tbody>
        {props.reporting.reports.map((report) => (
          <tr key={'round' + report.roundnum}>
            <td>{report.roundnum}</td>
            <td>{display_month(report.createdate)}</td>
            <td>
              {report.mortgagecases > 0 ? (
                <a
                  className="btn btn-info btn-sm me-2"
                  href={report.mortgage_url}
                >
                  <FontAwesomeIcon icon={faDownload} /> Mortgage list
                </a>
              ) : (
                ''
              )}
              {report.taxcases > 0 ? (
                <a className="btn btn-info btn-sm" href={report.tax_url}>
                  <FontAwesomeIcon icon={faDownload} /> Tax list
                </a>
              ) : (
                ''
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : null;
};

export const ReportList = (props) => {
  const dispatch = useDispatch();
  const reporting = useSelector((state) => state.reporting);
  const [tab, setTab] = useState('reports');
  useEffect(() => {
    dispatch(getReports());
  }, [dispatch]);
  const handleSelect = (newTab) => {
    setTab(newTab);
  };
  return (
    <Fragment>
      <Header text="Reports" back="back" />
      <Main>
        <Alerts />
        <div className="container pt-2">
          <Nav fill variant="tabs" activeKey={tab} onSelect={handleSelect}>
            <Nav.Item>
              <Nav.Link eventKey="reports">Reports</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="planning">Planning</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="statistics">Statistics</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <ActiveTab tab={tab} reporting={reporting} />
            </div>
          </div>
        </div>
      </Main>
    </Fragment>
  );
};

export default ReportList;
