import { GET_ROUNDS, DELETE_ROUND, ADD_ROUND } from '../actions/types.js';

const initialState = {
  rounds: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ROUNDS:
      return {
        ...state,
        rounds: action.payload,
      };
    case DELETE_ROUND:
      return {
        ...state,
        rounds: state.rounds.filter((round) => round.id != action.payload),
      };
    case ADD_ROUND:
      return {
        ...state,
        rounds: [...state.rounds, action.payload],
      };
    default:
      return state;
  }
}
