import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  getAgencies,
  getAgency,
  getAgencyMap,
  newAgency,
  updateAgency,
  getRoles,
  getTracts,
} from '../../actions/agencies';
import { textValidate, emailValidate } from '../../actions/functions';
import { displayMessage } from '../../actions/alerts';
import { AgencyMap } from './AgencyMap';
import Main from '../layout/Main';
import Header from '../layout/Header';
import Alerts from '../layout/Alerts';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Button } from 'react-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import CurrentLocation from '../../assets/current-32.png';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

export const AgencyNew = (props) => {
  const roles = useSelector((state) => state.agencies.roles);
  const tracts = useSelector((state) => state.agencies.tracts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let { agencyid } = useParams();
  useEffect(() => {
    dispatch(getRoles());
    dispatch(getTracts());
  }, [dispatch, agencyid]);
  const roleID = React.useRef(null);
  const censusTracts = React.useRef(null);
  const agencyName = React.useRef(null);
  return (
    <Fragment>
      <Header text="New agency" back="#/" />
      <Alerts />
      <Main>
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h3>Add new agency</h3>
              <Form
                onSubmit={(e) => {
                  if (!roleID.current.value) {
                    e.preventDefault();
                    dispatch(
                      displayMessage('You must select a role.', 'danger')
                    );
                    return false;
                  }
                  if (!textValidate(agencyName.current.value)) {
                    e.preventDefault();
                    dispatch(
                      displayMessage('You must enter a name.', 'danger')
                    );
                    return false;
                  }
                  var payload = {
                    name: agencyName.current.value,
                    role_id: roleID.current.value,
                  };
                  payload.census_tract_id = Array.prototype.slice
                    .call(censusTracts.current.selectedOptions)
                    .map((option) => {
                      return option.value;
                    });
                  e.preventDefault();
                  dispatch(newAgency(payload, navigate, setLoading));
                  setLoading(true);
                  return false;
                }}
              >
                <Form.Group className="mb-3" controlId="agencyName">
                  <Form.Label>Agency name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Agency"
                    ref={agencyName}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="role">
                  <Form.Label>Role</Form.Label>
                  <Form.Select aria-label="Role" ref={roleID}>
                    <option value="">Choose one...</option>
                    {roles && roles.length > 0
                      ? roles.map((role) => (
                          <option key={'role' + role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))
                      : null}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="censusTract">
                  <Form.Label>Census Tracts</Form.Label>
                  <select
                    multiple
                    id="censusTract"
                    aria-label="Census Tracts"
                    className="form-select"
                    size={12}
                    ref={censusTracts}
                  >
                    {tracts && tracts.length > 0
                      ? tracts.map((tract) => (
                          <option key={'tract' + tract.id} value={tract.id}>
                            {tract.name}
                          </option>
                        ))
                      : null}
                  </select>
                </Form.Group>
                {loading ? (
                  <Button variant="primary" disabled>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{' '}
                    Creating...
                  </Button>
                ) : (
                  <Button variant="primary" type="submit">
                    Create
                  </Button>
                )}
              </Form>
            </div>
          </div>
        </div>
      </Main>
    </Fragment>
  );
};

export const AgencyEdit = (props) => {
  const agency = useSelector((state) => state.agencies.agency);
  const roles = useSelector((state) => state.agencies.roles);
  const tracts = useSelector((state) => state.agencies.tracts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let { agencyid } = useParams();
  useEffect(() => {
    dispatch(getRoles());
    dispatch(getTracts());
    if (agencyid) {
      dispatch(getAgency(agencyid));
    }
  }, [dispatch, agencyid]);
  const isActive = React.useRef(null);
  const roleID = React.useRef(null);
  const censusTracts = React.useRef(null);
  return (
    <Fragment>
      <Header text={agency && agency.id ? agency.name : ''} back="#/" />
      <Alerts />
      <Main>
        {agency && agency.id ? (
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h3>Edit agency</h3>
                <Form
                  onSubmit={(e) => {
                    if (!roleID.current.value) {
                      e.preventDefault();
                      dispatch(
                        displayMessage('You must select a role.', 'danger')
                      );
                      return false;
                    }
                    var payload = {
                      id: agency.id,
                      is_active: isActive.current.checked,
                      role_id: roleID.current.value,
                    };
                    payload.census_tract_id = Array.prototype.slice
                      .call(censusTracts.current.selectedOptions)
                      .map((option) => {
                        return option.value;
                      });
                    e.preventDefault();
                    dispatch(updateAgency(payload, navigate, setLoading));
                    setLoading(true);
                    return false;
                  }}
                >
                  <Form.Group className="mb-3" controlId="isActive">
                    <Form.Switch
                      label="Active"
                      ref={isActive}
                      value="on"
                      defaultChecked={agency.is_active}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="agencyName">
                    <Form.Label>Agency name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Agency"
                      defaultValue={agency.name}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="role">
                    <Form.Label>Role</Form.Label>
                    <Form.Select
                      aria-label="Role"
                      ref={roleID}
                      defaultValue={agency.role.id}
                    >
                      <option value="">Choose one...</option>
                      {agency && roles && roles.length > 0
                        ? roles.map((role) => (
                            <option key={'role' + role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="censusTract">
                    <Form.Label>Census Tracts</Form.Label>
                    <select
                      multiple
                      id="censusTract"
                      aria-label="Census Tracts"
                      className="form-select"
                      size={12}
                      ref={censusTracts}
                      defaultValue={agency.censustracts}
                    >
                      {tracts && tracts.length > 0
                        ? tracts.map((tract) => (
                            <option key={'tract' + tract.id} value={tract.id}>
                              {tract.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </Form.Group>
                  {loading ? (
                    <Button variant="primary" disabled>
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{' '}
                      Saving...
                    </Button>
                  ) : (
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </Form>
                <h3 className="mt-3">NAC area</h3>
                {agencyid ? <AgencyMap agency={agencyid} /> : null}
              </div>
            </div>
          </div>
        ) : null}
      </Main>
    </Fragment>
  );
};

export const AgencyRow = (props) => {
  const navigate = useNavigate();
  return (
    <tr
      style={{ cursor: 'pointer' }}
      onClick={(event) => {
        event.preventDefault();
        navigate('/agencies/' + props.agency.id);
        return false;
      }}
    >
      <td>
        {props.agency.is_active ? (
          ''
        ) : (
          <span className="orinvalid">
            <FontAwesomeIcon icon="ban" title="Agency is disabled" />
          </span>
        )}
      </td>
      <td>{props.agency.name}</td>
    </tr>
  );
};

export const AgenciesTab = () => {
  const agencies = useSelector((state) => state.agencies);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAgencies());
  }, [dispatch]);
  return agencies && agencies.list && agencies.list.length > 0 ? (
    <div className="row">
      <div className="col-lg-6 offset-lg-3">
        <div className="text-end mt-1">
          <Link to="/agencyadd" className="btn btn-success">
            <FontAwesomeIcon icon="plus" /> Add agency
          </Link>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Agency</th>
            </tr>
          </thead>
          <tbody>
            {agencies.list.map((agency) => (
              <AgencyRow agency={agency} key={agency.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
};
