import React, { Component, Fragment } from 'react';
import {
  HashRouter as Router,
  Route,
  Routes,
  Redirect,
} from 'react-router-dom';

import Dashboard from './common/Dashboard';
import HomeScreen from './homes/HomeScreen';
import VisitScreen from './homes/VisitScreen';
import Homes from './homes/Homes';
import Login from './accounts/Login';
import PrivateRoute from './common/PrivateRoute';
import HelpScreen from './common/HelpScreen';
import ReportList from './report/ReportList';
import { UserEdit, UserNew, UserProfile, Password } from './users/Users';
import { AgencyNew, AgencyEdit } from './agencies/Agencies';

import { Provider } from 'react-redux';
import store from '../store';
import { loadUser } from '../actions/auth';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPencilAlt,
  faCircleCheck,
  faUser,
  faFileCircleCheck,
  faHouse,
  faBan,
  faEnvelope,
  faCircle,
  faM,
  faT,
  faSpinner,
  faDownload,
  faStar,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faPencilAlt,
  faCircleCheck,
  faUser,
  faFileCircleCheck,
  faHouse,
  faBan,
  faEnvelope,
  faCircle,
  faM,
  faT,
  faSpinner,
  faDownload,
  faStar,
  faPlus
);

import 'bootstrap/dist/css/bootstrap.min.css';
import Favicon from 'react-favicon';
import FaviconFile from '../assets/favicon.ico';

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }
  render() {
    return (
      <Fragment>
        <Favicon url={FaviconFile} />
        <Provider store={store}>
          <Router>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/agencies/:agencyid"
                element={
                  <PrivateRoute>
                    <AgencyEdit />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/agencyadd"
                element={
                  <PrivateRoute>
                    <AgencyNew />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/useradd"
                element={
                  <PrivateRoute>
                    <UserNew />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/password"
                element={
                  <PrivateRoute>
                    <Password />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/user/profile"
                element={
                  <PrivateRoute>
                    <UserProfile />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/users/:userid"
                element={
                  <PrivateRoute>
                    <UserEdit />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/report"
                element={
                  <PrivateRoute>
                    <ReportList />
                  </PrivateRoute>
                }
              />
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/round/:roundnum"
                element={
                  <PrivateRoute>
                    <Homes />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/home/:roundnum/:homenum"
                element={
                  <PrivateRoute>
                    <HomeScreen />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/visit/:roundnum/:homenum/:visitno"
                element={
                  <PrivateRoute>
                    <VisitScreen />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/help"
                element={
                  <PrivateRoute>
                    <HelpScreen />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Router>
        </Provider>
      </Fragment>
    );
  }
}

export default App;
