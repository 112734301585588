import React, { Component, Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ScrollToTop from '../common/ScrollToTop';
import { logout } from '../../actions/auth';
import { saveLocation } from '../../actions/geo';
import { useGeolocated } from 'react-geolocated';
import { SAVE_LOCATION } from '../../actions/types';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';

const Geo = () => {
  const dispatch = useDispatch();
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      dispatch({
        type: SAVE_LOCATION,
        payload: {
          coords: { latitude: coords.latitude, longitude: coords.longitude },
        },
      });
    }
  }, [dispatch, coords, isGeolocationAvailable, isGeolocationEnabled]);
  return;
};

function AutoBackButton(props) {
  const navigate = useNavigate();
  return (
    <a
      className="btn btn-outline-secondary"
      onClick={(e) => {
        e.preventDefault();
        navigate(-1);
        return false;
      }}
      href="#"
    >
      Back
    </a>
  );
}

function BackButton(props) {
  if (props.back == 'back') {
    return <AutoBackButton />;
  }
  return props.back ? (
    <a className="btn btn-outline-secondary" href={props.back}>
      Back
    </a>
  ) : null;
}

function BrandComponent(props) {
  return (
    <Fragment>
      <BackButton back={props.back} />
      <Navbar.Brand href="#" className="ms-2">
        {props.text}
      </Navbar.Brand>
    </Fragment>
  );
}

export class Header extends Component {
  render() {
    const { isAuthenticated, user } = this.props.auth;
    return (
      <>
        <Geo />
        <ScrollToTop />
        <Navbar bg="light" expand="lg" fixed="top">
          <Container fluid>
            <BrandComponent text={this.props.text} back={this.props.back} />
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto" align="end">
                {isAuthenticated ? (
                  <>
                    <Nav.Link
                      href="#/user/profile"
                      className="me-3"
                      title="User Profile"
                    >
                      {user.email}
                    </Nav.Link>
                    {user.id &&
                    user.agency.role.name == 'outreach' &&
                    this.props.text != 'Reports' ? (
                      <Link className="nav-link" to="/report">
                        Report
                      </Link>
                    ) : null}
                    {user.id &&
                    user.agency.role.name == 'outreach' &&
                    this.props.text != 'Help' ? (
                      <Link className="nav-link" to="/help">
                        Help
                      </Link>
                    ) : null}
                    <Nav.Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.logout();
                        return false;
                      }}
                    >
                      Logout
                    </Nav.Link>
                  </>
                ) : (
                  ''
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Header);
