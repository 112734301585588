import { UPDATE_NAV } from '../actions/types.js';

const initialState = {
  statistics: 'reports',
  admin: 'users',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_NAV:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
