import { combineReducers } from 'redux';
import rounds from './rounds';
import homes from './homes';
import errors from './errors';
import messages from './messages';
import auth from './auth';
import alerts from './alerts';
import geo from './geo';
import reporting from './reporting';
import users from './users';
import agencies from './agencies';
import nav from './nav';

export default combineReducers({
  rounds,
  homes,
  errors,
  messages,
  auth,
  alerts,
  geo,
  reporting,
  users,
  agencies,
  nav,
});
