import { SAVE_LOCATION } from '../actions/types.js';

const initialState = {
  coords: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_LOCATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
