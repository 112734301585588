import {
  GET_USERS,
  GET_USER,
  UPDATE_USER,
  NEW_USER,
} from '../actions/types.js';

const initialState = {
  user: null,
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        list: action.payload,
        user: null,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case NEW_USER:
    case UPDATE_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}
