import React, { Component, Fragment, createRef } from 'react';
import { connect, compose } from 'react-redux';
import PropTypes from 'prop-types';
import HomeInfo from './HomeInfo';
import {
  getHome,
  writeInfo,
  updateStatus,
  makeVisit,
  updateVisit,
  deleteVisit,
} from '../../actions/homes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Main from '../layout/Main';
import Header from '../layout/Header';
import Alerts from '../layout/Alerts';
import { Form, Button } from 'react-bootstrap';

export const visitTypes = [
  'spokewithowner',
  'spokewithother',
  'leftmaterials',
  'madeattempt',
];

export function displayVisitType(visitType) {
  switch (visitType) {
    case 'spokewithowner':
      return 'Spoke with owner';
    case 'spokewithother':
      return 'Spoke with other';
    case 'leftmaterials':
      return 'Left materials';
    case 'madeattempt':
      return 'Made attempt';
    default:
      return 'Error';
  }
}

function parseISOLocalTime(s) {
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4]);
}

export function formatDateTime(datetime) {
  if (!datetime) {
    return '';
  }
  var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  var the_datetime = parseISOLocalTime(datetime);
  return new Intl.DateTimeFormat('en-US', options).format(the_datetime);
}

function parseISOLocal(s) {
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2]);
}

export function formatDate(date) {
  if (!date) {
    return '';
  }
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  var the_date = parseISOLocal(date);
  return new Intl.DateTimeFormat('en-US', options).format(the_date);
}

class VisitTable extends Component {
  render() {
    if (this.props.visits.length == 0) {
      return null;
    }
    return (
      <Fragment>
        <h3 className="mt-3">Recorded Visits</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Result</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {this.props.visits.map((visit) => (
              <tr key={visit.id}>
                <td>{formatDate(visit.visitdate)}</td>
                <td>{displayVisitType(visit.visittype)}</td>
                <td>
                  <Link
                    className="btn btn-sm btn-success"
                    to={`/visit/${this.props.roundnum}/${this.props.homenum}/${visit.id}`}
                  >
                    <FontAwesomeIcon icon="pencil-alt" /> Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

class SavedButton extends Component {
  componentDidUpdate() {
    if (this.props.status === 'saved') {
      this.timeout = setTimeout(() => {
        this.props.notifiedAboutSave();
      }, 2000);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    return (
      <Button
        type="submit"
        variant={
          this.props.status === 'saved'
            ? 'success'
            : this.props.status === 'saving'
            ? 'info'
            : this.props.pageChanged
            ? 'primary'
            : this.props.status === 'errorUpdateHome'
            ? 'danger'
            : 'primary'
        }
        className={
          this.props.status === 'ok' && !this.props.pageChanged
            ? 'invisible'
            : null
        }
        disabled={!this.props.pageChanged}
      >
        {this.props.status === 'saved'
          ? 'Changes saved'
          : this.props.status === 'saving'
          ? 'Saving changes...'
          : this.props.status === 'errorUpdateHome'
          ? 'There was an error. Unable to save information.'
          : 'Save changes'}
      </Button>
    );
  }
}

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageChanged: false,
    };
    this.comments = createRef();
    this.phone = createRef();
    this.hotline = createRef();
    this.vacant = createRef();
    this.rental = createRef();
    this.badaddress = createRef();
    this.sentmail = createRef();
    this.onChange = this.onChange.bind(this);
  }

  static propTypes = {
    home: PropTypes.object.isRequired,
    getHome: PropTypes.func.isRequired,
    writeInfo: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
  };

  onMakeVisit = (e) => {
    document.activeElement.blur();
    e.preventDefault();
    const payload = {
      roundnum: this.props.roundnum,
      indexno: this.props.homenum,
      visittype: e.target.value,
      latitude: this.props.coords.latitude,
      longitude: this.props.coords.longitude,
    };
    this.props.makeVisit(payload);
    this.props.updateStatus('makingVisit', e.target.value);
  };

  onChange = (e) => {
    this.setState({
      pageChanged: true,
    });
  };

  onClick = (e) => {
    document.activeElement.blur();
    this.setState({
      pageChanged: true,
    });
  };

  notifiedAboutSave = () => {
    this.props.updateStatus('ok');
  };

  saveInfo = (e) => {
    document.activeElement.blur();
    e.preventDefault();
    const payload = {
      roundnum: this.props.roundnum,
      indexno: this.props.homenum,
      comments: this.comments.current.value || '',
      phone: this.phone.current.value || '',
      hotline: this.hotline.current.value || '',
      sentmail: this.sentmail.current.checked,
      vacant: this.vacant.current.checked,
      rental: this.rental.current.checked,
      badaddress: this.badaddress.current.checked,
    };
    this.props.updateStatus('saving');
    this.props.writeInfo(payload);
    this.setState({
      pageChanged: false,
    });
  };

  componentDidMount() {
    this.props.getHome(this.props.roundnum, this.props.homenum);
  }

  render() {
    var home = this.props.home;
    return home.indexno ? (
      <Fragment>
        <Header
          text={'Home ' + home.indexno}
          back={'#/round/' + this.props.roundnum}
        />
        <Main>
          <Alerts />
          <div className="container">
            <HomeInfo home={home} />
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="d-grid gap-2">
                  <Button
                    type="button"
                    variant={
                      this.props.status == 'makingVisit' &&
                      this.props.subStatus == 'spokewithowner'
                        ? 'info'
                        : 'primary'
                    }
                    value="spokewithowner"
                    onClick={this.onMakeVisit}
                    disabled={this.props.status == 'makingVisit'}
                  >
                    Spoke with owner
                  </Button>
                  <Button
                    variant={
                      this.props.status == 'makingVisit' &&
                      this.props.subStatus == 'spokewithother'
                        ? 'info'
                        : 'primary'
                    }
                    value="spokewithother"
                    onClick={this.onMakeVisit}
                    disabled={this.props.status == 'makingVisit'}
                  >
                    Spoke with other person
                  </Button>
                  <Button
                    variant={
                      this.props.status == 'makingVisit' &&
                      this.props.subStatus == 'leftmaterials'
                        ? 'info'
                        : 'primary'
                    }
                    value="leftmaterials"
                    onClick={this.onMakeVisit}
                    disabled={this.props.status == 'makingVisit'}
                  >
                    Left material
                  </Button>
                  <Button
                    variant={
                      this.props.status == 'makingVisit' &&
                      this.props.subStatus == 'madeattempt'
                        ? 'info'
                        : 'primary'
                    }
                    value="madeattempt"
                    onClick={this.onMakeVisit}
                    disabled={this.props.status == 'makingVisit'}
                  >
                    Made attempt
                  </Button>
                </div>
                <VisitTable
                  roundnum={this.props.roundnum}
                  homenum={this.props.homenum}
                  visits={home.visits}
                />
                <h3 className="mt-3">About the address</h3>
                <Form onSubmit={this.saveInfo}>
                  <Form.Group className="mb-3">
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      onChange={this.onChange}
                      defaultValue={home.info.comments}
                      ref={this.comments}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control
                      onChange={this.onChange}
                      defaultValue={home.info.phone}
                      ref={this.phone}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      onChange={this.onChange}
                      defaultValue={home.info.hotline}
                      ref={this.hotline}
                    >
                      <option disabled>Choose one...</option>
                      <option value="na">N/A</option>
                      <option value="willcall">Will call Hotline</option>
                      <option value="calledonspot">Called on spot</option>
                      <option value="alreadycalled">Already called</option>
                      <option value="hascounselor">Has counselor</option>
                      <option value="takencareofit">Took care of it</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" key={'checkboxes'}>
                    <Form.Check
                      onClick={this.onChange}
                      value="on"
                      id="sentmail"
                      defaultChecked={home.info.sentmail}
                      ref={this.sentmail}
                      label="Sent mail"
                    />
                    <Form.Check
                      onClick={this.onChange}
                      value="on"
                      id="vacant"
                      defaultChecked={home.info.vacant}
                      ref={this.vacant}
                      label="Vacant"
                    />
                    <Form.Check
                      onClick={this.onChange}
                      value="on"
                      id="rental"
                      defaultChecked={home.info.rental}
                      ref={this.rental}
                      label="Rental"
                    />
                    <Form.Check
                      onClick={this.onChange}
                      value="on"
                      id="badaddress"
                      defaultChecked={home.info.badaddress}
                      ref={this.badaddress}
                      label="Bad address"
                    />
                  </Form.Group>
                  <div className="d-grid gap-2">
                    <SavedButton
                      status={this.props.status}
                      pageChanged={this.state.pageChanged}
                      notifiedAboutSave={this.notifiedAboutSave}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Main>
      </Fragment>
    ) : (
      <Fragment />
    );
  }
}

const mapStateToProps = (state) => ({
  home: state.homes.home,
  status: state.homes.status,
  subStatus: state.homes.subStatus,
  coords: state.geo.coords,
});

export default connect(mapStateToProps, {
  getHome,
  writeInfo,
  updateStatus,
  makeVisit,
  updateVisit,
  deleteVisit,
})(Home);
