import React, { Component, Fragment } from 'react';
import { formatDate, formatDateTime } from './Home';

export default class HomeInfo extends Component {
  render() {
    var home = this.props.home;
    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="card mb-3 mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 col-sm-3">
                    <div className="fw-bold">Name</div>
                    <span>{home.name}</span>
                  </div>
                  <div className="col-6 col-sm-3">
                    <div className="fw-bold">Address</div>
                    <span>{home.numberprefixstreettype}</span>
                  </div>
                  <div className="col-6 col-sm-3">
                    <div className="fw-bold">Docket number</div>
                    <span>{home.docketnum}</span>
                  </div>
                  <div className="col-6 col-sm-3">
                    <div className="fw-bold">Conference</div>
                    <span>{formatDateTime(home.ccdatetime)}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-sm-3">
                    <div className="fw-bold">Type</div>
                    <span>{home.casetype}</span>
                  </div>
                  <div className="col-6 col-sm-3">
                    <div className="fw-bold">Caption</div>
                    <span>{home.caption}</span>
                  </div>
                  <div className="col-6 col-sm-3">
                    <div className="fw-bold">Case filed</div>
                    <span>{formatDate(home.dfiled)}</span>
                  </div>
                  <div className="col-6 col-sm-3">
                    <div className="fw-bold">Special</div>
                    {home.isreverse && (
                      <div className="text-alert">Reverse Mortgage</div>
                    )}
                    {home.senior && <div className="text-alert">Senior</div>}
                    {home.senior || home.isreverse || <span>None</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
