import React, { Component, Fragment, createRef } from 'react';
import { connect, compose } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getHome,
  getVisit,
  getHomeAndVisit,
  updateStatus,
  updateVisit,
  deleteVisit,
} from '../../actions/homes';
import { visitTypes, displayVisitType } from './Home';
import HomeInfo from './HomeInfo';
import Main from '../layout/Main';
import Header from '../layout/Header';
import Alerts from '../layout/Alerts';
import Confirm from '../common/Confirm';
import { Form, Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

class SavedButton extends Component {
  componentDidUpdate() {
    if (this.props.status === 'saved') {
      this.timeout = setTimeout(() => {
        this.props.notifiedAboutSave();
      }, 2000);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    return (
      <Button
        type="submit"
        variant={
          this.props.status === 'saved'
            ? 'success'
            : this.props.status === 'saving'
            ? 'info'
            : this.props.status === 'deleting'
            ? 'info'
            : this.props.pageChanged
            ? 'primary'
            : this.props.status === 'errorUpdateVisit'
            ? 'danger'
            : 'primary'
        }
        className={
          this.props.status === 'ok' && !this.props.pageChanged
            ? 'invisible'
            : null
        }
        disabled={!this.props.pageChanged}
      >
        {this.props.status === 'saved'
          ? 'Changes saved'
          : this.props.status === 'saving'
          ? 'Saving changes...'
          : this.props.status === 'deleting'
          ? 'Deleting visit...'
          : this.props.status === 'errorUpdateVisit'
          ? 'There was an error. Unable to save information.'
          : 'Save changes'}
      </Button>
    );
  }
}

export class Visit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageChanged: false,
    };
    this.visittype = createRef();
    this.visitdate = createRef();
    this.timetext = createRef();
    this.onChange = this.onChange.bind(this);
  }

  /* static propTypes = {
   *   home: PropTypes.object.isRequired,
   *   visit: PropTypes.object.isRequired,
   *   updateVisit: PropTypes.func.isRequired,
   * };
   */
  onChange = (e) => {
    this.setState({
      pageChanged: true,
    });
  };

  notifiedAboutSave = () => {
    this.props.updateStatus('ok');
  };

  deleteVisit = (e) => {
    this.props.updateStatus('deleting');
    this.props.deleteVisit(this.props.visitno);
    this.setState({
      pageChanged: false,
    });
  };

  saveInfo = (e) => {
    document.activeElement.blur();
    e.preventDefault();
    const payload = {
      id: this.props.visitno,
      visitdate: this.visitdate.current.value,
      timetext: this.timetext.current.value,
      visittype: this.visittype.current.value,
    };
    this.props.updateStatus('saving');
    this.props.updateVisit(payload);
    this.setState({
      pageChanged: false,
    });
  };

  componentDidMount() {
    this.props.getHomeAndVisit(
      this.props.roundnum,
      this.props.homenum,
      this.props.visitno
    );
  }

  render() {
    var home = this.props.home;
    var visit = this.props.visit;
    if (visit == 'deleted') {
      return (
        <Navigate
          to={'/home/' + this.props.roundnum + '/' + this.props.homenum}
        />
      );
    }
    return (
      <Fragment>
        <Header
          text={'Visit to home ' + this.props.homenum}
          back={'#/home/' + this.props.roundnum + '/' + this.props.homenum}
        />
        <Alerts />
        <Main>
          <div className="container mb-4">
            {home ? <HomeInfo home={home} /> : null}
            <div className="row">
              <div className="col-md-6 offset-md-3">
                {visit ? (
                  <Form onSubmit={this.saveInfo}>
                    <div className="mb-3">
                      <div>
                        <h3>
                          Edit visit
                          <Confirm
                            onConfirm={this.deleteVisit}
                            body="Are you sure you want to delete this visit?"
                            confirmText="Yes, delete"
                            title="Confirm delete"
                          >
                            <Button variant="secondary" className="float-end">
                              Delete this visit
                            </Button>
                          </Confirm>
                        </h3>
                      </div>
                    </div>
                    <Form.Group className="mb-3" controlId="visittype">
                      <Form.Label>Visit type</Form.Label>
                      <Form.Select
                        aria-label="Visit type"
                        ref={this.visittype}
                        onChange={this.onChange}
                        ref={this.visittype}
                      >
                        {visitTypes.map((visittype) => (
                          <option
                            value={visittype}
                            key={visittype}
                            selected={visit.visittype === visittype}
                          >
                            {displayVisitType(visittype)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Visit date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={this.onChange}
                        defaultValue={visit.visitdate}
                        ref={this.visitdate}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Time of day</Form.Label>
                      <Form.Select onChange={this.onChange} ref={this.timetext}>
                        <option value="day" selected={visit.timetext === 'day'}>
                          Day
                        </option>
                        <option
                          value="evening"
                          selected={visit.timetext === 'evening'}
                        >
                          Evening
                        </option>
                      </Form.Select>
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <SavedButton
                        status={this.props.status}
                        pageChanged={this.state.pageChanged}
                        notifiedAboutSave={this.notifiedAboutSave}
                      />
                    </div>
                  </Form>
                ) : null}
              </div>
            </div>
          </div>
        </Main>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  home: state.homes.home,
  visit: state.homes.visit,
  status: state.homes.status,
  subStatus: state.homes.subStatus,
});

export default connect(mapStateToProps, {
  getHome,
  getVisit,
  getHomeAndVisit,
  updateStatus,
  updateVisit,
  deleteVisit,
})(Visit);
