import axios from 'axios';
import {
  GET_REPORTS,
  GET_REPORT,
  LOADING_REPORT,
  GET_STATISTICS,
  LOADING_STATISTICS,
} from './types';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

export const getReports = (agency_id) => (dispatch, getState) => {
  const config = tokenConfig(getState);
  config['params'] = { agency_id: agency_id };
  axios
    .get('/api/reportlist', config)
    .then((res) => {
      dispatch({
        type: GET_REPORTS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getReport = (payload) => (dispatch, getState) => {
  dispatch({
    type: LOADING_REPORT,
    roundnum: payload.roundnum,
  });
  const config = tokenConfig(getState);
  config['params'] = payload;
  axios
    .get('/api/report', config)
    .then((res) => {
      dispatch({
        type: GET_REPORT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getStatistics = (payload, setLoading) => (dispatch, getState) => {
  dispatch({
    type: LOADING_STATISTICS,
  });
  const config = tokenConfig(getState);
  config['params'] = payload;
  axios
    .get('/api/statistics', config)
    .then((res) => {
      dispatch({
        type: GET_STATISTICS,
        payload: res.data,
      });
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
