export const GET_HOMES = 'GET_HOMES';
export const GET_HOME = 'GET_HOME';
export const GET_HOME_AND_VISIT = 'GET_HOME_AND_VISIT';
export const UPDATE_HOME = 'UPDATE_HOME';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_VISIT = 'UPDATE_VISIT';
export const DELETE_VISIT = 'DELETE_VISIT';
export const GET_VISIT = 'GET_VISIT';
export const GET_ROUNDS = 'GET_ROUNDS';
export const DELETE_ROUND = 'DELETE_ROUND';
export const ADD_ROUND = 'ADD_ROUND';
export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SAVE_LOCATION = 'SAVE_LOCATION';
export const UPDATE_ROUND = 'UPDATE_ROUND';
export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORT = 'GET_REPORT';
export const LOADING_REPORT = 'LOADING_REPORT';
export const GET_STATISTICS = 'GET_STATISTICS';
export const LOADING_STATISTICS = 'LOADING_STATISTICS';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_AGENCIES = 'GET_AGENCIES';
export const GET_AGENCY = 'GET_AGENCY';
export const GET_AGENCY_MAP = 'GET_AGENCY_MAP';
export const NEW_AGENCY = 'NEW_AGENCY';
export const UPDATE_AGENCY = 'UPDATE_AGENCY';
export const UPDATE_NAV = 'UPDATE_NAV';
export const NEW_USER = 'NEW_USER';
export const GET_ROLES = 'GET_ROLES';
export const GET_TRACTS = 'GET_TRACTS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
